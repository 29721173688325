import React from "react"
import { PageLayout } from "../components"
import { Container } from "react-bootstrap"
//import {  graphql } from "gatsby"
import { SEO } from "../utils"
import { Link } from "@reach/router"

import { Line } from 'react-chartjs-2';



export default ({ data }) => {

  
  function GetZPercent(z) 
  {
    //z == number of standard deviations from the mean

    //if z is greater than 6.5 standard deviations from the mean
    //the number of significant digits will be outside of a reasonable 
    //range
    if ( z < -6.5)
      return 0.0;
    if( z > 6.5) 
      return 1.0;

    var factK = 1;
    var sum = 0;
    var term = 1;
    var k = 0;
    var loopStop = Math.exp(-23);
    while(Math.abs(term) > loopStop) 
    {
      term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
      sum += term;
      k++;
      factK *= k;

    }
    sum += 0.5;

    return sum;
  }
  let points = []
  let labelPoints = []
  let axisColor = []
  let axisWidth = []
  //console.log("in bell curve data is", data)

  let sigma = parseFloat(data.sigma), mu = parseFloat(data.mu), userScore = parseFloat(data.score)

  
  //let q = ( ( 1/ (sigma*Math.sqrt(2*Math.PI)) ) * ( Math.pow(Math.E,(-1*( Math.pow((0-mu),2)/ Math.pow((2*sigma),2) ) ) ) ) )
  //console.log('sigma is',sigma,'mu is',mu,'y is',q)
  for(let i = -3; i <= 3.1; i+=0.05)
  {
    let z = (i)
    //let y = ( ( 1/ (sigma*Math.sqrt(2*Math.PI)) ) * ( Math.pow(Math.E,(-1*( Math.pow((z-mu),2)/ Math.pow((2*sigma),2) ) ) ) ) )
    let y = (1/(Math.sqrt(2*3.14159)))*(Math.pow(2.718,(-1*(Math.pow(z,2))/2)))
    //let y = - (Math.pow(i,2) - 2)

    points.push({"x":String((z.toFixed(2))),"y":String((y.toFixed(10) ))})
    labelPoints.push(String((z.toFixed(2))))
    if (Math.abs(Math.round(z * 10)) == 0)
    {
      axisColor.push("grey")
      axisWidth.push(3)
    }
    axisColor.push("black")
    axisWidth.push(0.5)
  }
  //console.log("points",points, "|colors",axisColor)

  let zScore = ((userScore - mu) / sigma)
  let percentile = GetZPercent(zScore)
  //console.log("your percentile is:",percentile,"z score is:",zScore)

  let filledPoints = []
  for(let i = -3; i <= zScore; i+=0.05)
  {
    let z = (i)
    //let y = ( ( 1/ (sigma*Math.sqrt(2*Math.PI)) ) * ( Math.pow(Math.E,(-1*( Math.pow((z-mu),2)/ Math.pow((2*sigma),2) ) ) ) ) )
    let y = (1/(Math.sqrt(2*3.14159)))*(Math.pow(2.718,(-1*(Math.pow(z,2))/2)))
    //let y = - (Math.pow(i,2) - 2)

    filledPoints.push({"x":String((z.toFixed(2))),"y":String((y.toFixed(10) ))})
    
  }

  let yPoint = ( (1/(Math.sqrt(2*3.14159)))*(Math.pow(2.718,(-1*(Math.pow(zScore,2))/2))) ) *1.1
  let vertPoints = []
  /*for(let i = -3; i <= zScore; i+=0.1)
  {
    let z = (i)
    //let y = ( ( 1/ (sigma*Math.sqrt(2*Math.PI)) ) * ( Math.pow(Math.E,(-1*( Math.pow((z-mu),2)/ Math.pow((2*sigma),2) ) ) ) ) )
    let y = 0
    //let y = - (Math.pow(i,2) - 2)

    vertPoints.push({"x":String((z.toFixed(2))),"y":String((y.toFixed(10) ))})
    
  }*/
  let zzz =  parseFloat(parseInt(zScore * 10) / 10)

  vertPoints.push({"x":String((zzz.toFixed(2))),"y":"0.00"})
  vertPoints.push({"x":String((zzz.toFixed(2))),"y":String((yPoint))})
  //console.log(vertPoints)

  const dataParam = 
  {
    labels: labelPoints,
    datasetIdKey:'id',
    datasets: [
      {
        id:2,
        data: filledPoints,
        fill: true,
        backgroundColor: 'rgb(0, 221, 0, 0.5)',
        borderColor: 'rgba(0, 221, 0, 0.5)',
        borderWidth: 4,
        tension:0.3,
      },
      {
        id:3,
        data: points,
        fill: false,
        backgroundColor: 'rgb(0, 221, 0)',
        borderColor: 'rgba(0, 221, 0, 0.5)',
        borderWidth: 4,
        tension:0.3,
      },
      {
        id:1,
        data: vertPoints,
        fill: false,
        backgroundColor: '#aaa',
        borderColor: '#fff',
        borderWidth:0,
        tension:0.3,
      },
      
    ],
  };

  const options = {
    parsing: {
      xAxisKey: "x",
      yAxisKey: "y"
    },
    plugins:{
      legend:{
        display: false,
      },
    },
    elements:{
      point:{
          radius: 0,
      }
    },
    scales: {
      y: {
        min: 0,
        max: 0.45,
        grid:{
          color:["grey","black","black","black","black","black","black","black","black","black","black","black","black"],
          lineWidth: [3,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5],
        },
        ticks:
        {
          display: false,
          font:
          {
            size:10,
            weight:"900",
          }
        }
      },
      x:{
        grid:{
          color:axisColor,
          lineWidth: axisWidth,
        },
        ticks:
        {
          font:
          {
            size:10,
            weight:"900",
          }
        }
      }
    },
    responsive : true,
    maintainAspectRatio: false
  };


  return (
    <div className=" text-color" style={{ maxWidth: "800px",paddingLeft:"0px !",paddingRight:"0px !" }}>
      
      
      <div className="bellCurve">
        <Line data={dataParam} options={options} />
      </div>
      


      

    </div>
  )
}
