import React, {useState, useContext, useEffect, memo, useCallback } from "react"
import ThemeContext from "../utils/theme"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import { Container, Image, InputGroup, FormControl, Form, Button, Modal, CloseButton, ProgressBar, OverlayTrigger, Popover, Table} from "react-bootstrap"
import { Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import firebase from "../components/firebase"

import { MapContainer, TileLayer, Marker, Popup, Circle,useMap } from 'react-leaflet'

import { UserData }  from  "../components/UserData"
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './grid.css';


import { loggedUser, printing } from  "../components/FirebaseAuth"
import { LoadStateHouse, LoadRandomHouse } from  "../components/LoadStateHouse"
import { Link } from "@reach/router"

import BellCurve from "../components/BellCurve"


export default ({location}) => {

  let currentUser = loggedUser()

  //let userData = UserData()
  const [userData, setUserData] = useState({})

  const[mlsID,setmlsID] = useState("")

  const [alreadyPlayed,setAlreadyPlayed] = useState();
  const [previousScore,setPreviousScore] = useState(0);
  const [houseData,setHouse] = useState({});
  const [pics, setPictures] = useState([]);
  const [feats, setFeats] = useState([{category:"filler",text:["filler", "filler"]}]);
  
  const [taxAss, setTaxAss] = useState([{assessment:{total:"filler"},year:"filler"}]);
  const [readTax, setReadTax] = useState(false)
  const [taxModalShow, setTaxModalShow] = useState(false);

  const [trendModalShow, setTrendModalShow] = useState(false);
  const [trend, setTrend] = useState({median:"filler"});
  const [readTrend, setReadTrend] = useState(false)

  const [listItems, setListItems] = useState(null)

  const [priceSub, setPriceSub] = useState(0);
  const [priceComma, setPriceComma] = useState();
  const [hideInput, setHideInput] = useState(false);

  const [ogGuess, setOgGuess] = useState(0);
  const [priceDiff, setPriceDiff] = useState(0);
  const [percentageScore, setPercentageScore] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const { dark } = useContext(ThemeContext)
  
  const [meanScore, setMeanScore] = useState(1.0)
  const [stdDevSigma, setStdDevSigma] = useState(1.0)

  const [rerendering, setRerender] = useState(0.0)
  const [mapModalShow, setMapModalShow] = useState(false);

  let houseSize = 0
  let houseUnits = ""
  let housePrice = 0
  let houseCity = ""
  let houseState = ""
  let houseLine = ""
  let houseZip = ""
  let houseLat = 0
  let houseLon = 0
  let houseYear = 0

  const [shareOutput, setShareOutput] = useState('');
 
  const [dailyHouseVars,setDailyHouseVars] = useState({});  
  
  let dailyList = [ "M9170056565_RI",
                    "M8686638647_LA",
                    "M7830179733_LA",
                    "M9564664712_ID",
                    "M9077088995_ID",
                    "M5991137119_NJ",
                    "M1966510292_ID",
                    "M9239855212_TX",
                    "M4195415168_MI",
                    "M7575594712_MS",
                    "M7748332780_MS",
                    "M9138171415_MT",
                    "M7397671359_MT",
                    "M7012230511_IA",
                    "M8520035029_IA",
                    "M5154480603_SC",
                    "M5084680822_SC",
                    "M6488382672_SC",
                    "M9644614968_NJ",
                    "M8665237938_AR",
                    "M7875890587_AR",
                    "M8104221646_AR",
                    "M1927703851_ID",
                    "M9120926058_NM",
                    "M8187403385_AK",
                    "M9518084456_MT",
                    "M5477443741_DE",
                    "M8628995755_LA",
                    "M7838859429_LA",
                    "M4721900943_NY",
                    "M4785320056_NY",
                    "M4335688852_NY",
                    "M3623478575_CT",
                    "M4385419535_CT",
                    "M3424272028_CT",
                    "M4970060479_CT",
                    "M6604904318_MD",
                    "M6866287639_MD",
                    "M6848027327_MD",
                    "M6085938803_MD",
                    "M4317786525_PA",
                    "M4750877307_PA",
                    "M3472201440_PA",
                    "M3434309865_PA",
                    "M4111182365_PA",
                    "M3110062414_PA",
                    "M4199380152_NH",
                    "M4872801626_NH",
                    "M2925702696_CA",
                    "M1191532887_CA",
                    "M2887983051_CA",
                    "M2901684994_CA",
                    "M2962802303_CA",
                    "M1728490516_CA",
                    "M8524835765_NE",
                    "M7956885132_NE",
                    "M7079925302_NE",
                    "M7666240525_NE",
                    "M7599402575_NE",
                    "M8319829518_NE",
                    "M7855237398_AK",
                    "M8342280581_AK",
                    "M3559163092_MI",
                    "M4342738688_MI",
                    "M4370505087_MI",
                    "M2602550906_NM",
                    "M1575703879_NM",
                    "M2004480528_NM",
                    "M1380894990_NM",
                    "M7074547359_OK",
                    "M9535943353_OK",
                    "M8914616978_OK",
                    "M8263363117_OK",
                    "M8176974546_OK",
                    "M8264476020_OK",
                    "M2348502253_AZ",
                    "M2429798166_AZ",
                    "M1733563118_AZ",
                    "M1410334076_AZ",
                    "M5665826413_FL",
                    "M5199462188_FL",
                    "M6373270654_FL",
                    "M6865107387_FL",
                    "M5545424677_FL",
                    "M6444862166_FL",
                    "M3429476908_ME",
                    "M3807604276_ME",
                    "M9043353765_ME",
                    "M3371975558_ME",
                    "M3682336934_ME",
                    "M4851397677_VT",
                    "M4671962164_VT",
                    "M9636159244_VT",
                    "M4247179058_VT",
                    "M3220913980_MA",
                    "M3639254450_MA",
                    "M3787774271_KY",
                    "M3826083660_KY",
                    "M4088544513_KY",
                    "M4619168032_KY",
                    "M4126294913_KY",]
  
  function getStateMLS()
  {

    let start = "03/02/2022"   
    let todaysDate = new Date()
    let startDate = new Date(start)
    let diffTime = todaysDate.getTime() - startDate.getTime()
    let diffDays = Math.trunc(diffTime / (1000 * 3600 * 24) )

    //console.log("todays date is",todaysDate)


    let choice = diffDays%(dailyList.length)

    let mlsParam, stateParam
    let dailyParams = String(dailyList[choice]) 
    if (dailyParams.length > 0)
    {
      let divider = dailyParams.search("_")
      mlsParam = dailyParams.substring(0,divider)
      stateParam = dailyParams.substring((divider+1))
    }
    let ret = {"mlsID":mlsParam,"stateID":stateParam}

    setDailyHouseVars(ret)

    return ret

  }


  const GetHouseData = async(localUserData) =>
  {

    
    //console.log("------- CALLED GET HOUSE DATA")
    let altReturn = {}

    let houseVars = getStateMLS()
    let mlsParam = houseVars.mlsID 
    let stateParam = houseVars.stateID

    console.log(mlsParam,stateParam)

    let dailyStorage
    if (typeof window !== 'undefined') 
    { dailyStorage = JSON.parse(localStorage.getItem('daily')) }

    if(dailyStorage != null)
    {

      let timeStored = (new Date(parseInt(dailyStorage.time))).getTime()
      //timeStored = 1645851600000

      let todaysDate = new Date()
      let diffTime = todaysDate.getTime() - timeStored
      let diffDays = Math.trunc(diffTime / (1000 * 3600 * 24) )

      //console.log("it has been",diffDays,"since daily was updated", new Date("2/26/2022").getTime())

      
      let played = false


      if (dailyStorage != null)
      {

        //if(diffDays == 0)
        //{
          
          let keyName = `${mlsParam}_${stateParam}`
          //console.log("keyName:", keyName, "|", dailyStorage.key, (String(dailyStorage.key)).localeCompare(String(keyName)));
          if( (String(dailyStorage.key)).localeCompare(String(keyName)) == 0 )
          {
            //console.log("has already played this map")
            //played = true
            let ret = {"played":true, "points":dailyStorage.points}

            altReturn = ret

            let results = []
            let percent 
            let dailyAccuracy
            if(dailyStorage.percent == null)
            {
              dailyAccuracy = parseInt(dailyStorage.points)/500
            }
            else
            {
              percent = parseFloat(dailyStorage.percent)
              dailyAccuracy = (1-percent)
            }

            if (dailyAccuracy < 0)
            { dailyAccuracy = 0} 

            let dailyPoints = dailyStorage.points
            let maxBox = 8
            let sc = Math.floor(dailyAccuracy*maxBox)

            //let dayEpoch = new Date()
            //console.log((String(dayEpoch.getMonth()+1)).padStart(2, '0'))
            //let dayID = (String(dayEpoch.getMonth()+1)).padStart(2, '0').concat((String(dayEpoch.getDate())).padStart(2, '0'))
            //let dayIDYear = String(String(dayEpoch.getFullYear())).concat(dayID)
            
            let dayID = dailyList.indexOf(keyName) + 1
            results.push(`#Appraisir #Appraisir${dayID}\n`)
            results.push(`📈${Math.floor(dailyAccuracy*100)}% ✨${dailyPoints}\n`)

            //results.push("🏘️🌳🏘️🌳🏘️🌳🏘️🌳 \n")
            //results.push(`💲${dailyDiff}🔺 \n`)
            for (let i = 0; i < maxBox; i++) 
            {
              if(i < sc)
              { results.push('🟩') }
              else
              { results.push('🟥') }
            }
            results.push('\n')
            results.push("https://Appraisir.com/Daily \n")
            let tempShareOutput = String(results.join(""))

            setShareOutput(tempShareOutput)


            //console.log("return",ret)
          }
        //}
      }
      else
      {
        console.log("user DATA is null")
      }
    }


    //console.log("RUNNING States FIREBASE CALL")
    return firebase.firestore().collection('States').doc(String(stateParam)).collection('buffer').doc(mlsParam).get()
    .then((doc) => {
      if (doc.exists) 
      {
          //console.log("Document data:", doc.data());
          altReturn["house"] = doc.data()
          return altReturn
      } 
      else 
      {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
  }

  const loadPageData = (pageData) =>
  {
    setAlreadyPlayed(false)
    setHouse(pageData)
    let tempPic = []
    pageData.photos.forEach(element => {
      let str = element.href
      let trim = str.substring(0, (str.length-5))
      let better = trim.concat("od-w1024_h768_x2.webp")
      tempPic.push({src:better})
    });

    setFeats(pageData.features)
    setTaxAss(pageData.tax_history)
    setPictures(tempPic)

    
    if(pageData.trend && pageData.trend.median)
    {

      let propData = pageData.trend.median.by_prop_type
      //console.log("trend Data",propData)
      setTrend(propData)
    }
    else
    {
      let propData = {median:'none'}
      setTrend(propData)
    }
  }


  useEffect(() => 
  {
    
    (async () =>
    { 
      houseLat = 0
      houseLon = 0
      let userStorage = JSON.parse(localStorage.getItem('userData'))
      if (userStorage == null)
      {
        if(currentUser && currentUser.uid)
        {
          //console.log("RUNNING Users FIREBASE CALL")
          await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
          .then((doc) => {
            if (doc.exists) 
            {
                //console.log("Document data:", doc.data());
                //doc.data()
                setUserData(doc.data())
                localStorage.setItem('userData', JSON.stringify(doc.data()) )
            } 
            else 
            {
                // doc.data() will be undefined in this case
                console.log("No such user!");
            }
          }).catch((error) => 
          {
              console.log("Error getting user:", error);
          });
        }
      }
      else 
      {
        setUserData(userStorage)
        //console.log("didnt run firebase call because data is in local storage", userStorage)
      }
      

      let searchParams
      if (typeof window !== 'undefined') 
      { searchParams = new URLSearchParams(window.location.search); }


      //console.log("there was no houseinfo")
      let getHouse
      if (userStorage != null)
      {  
        getHouse = await GetHouseData(userStorage) 
        //console.log("ran with userStorage",userStorage)
      }
      else
      { 
        getHouse = await GetHouseData(userData)  
        //console.log("ran with userdata",userData)
      }
      if(getHouse["played"] == undefined)
      {
        loadPageData(getHouse["house"])
      }
      else
      {
        loadPageData(getHouse["house"])
        setPreviousScore(getHouse["points"])
        setAlreadyPlayed(true)
      }

    })()
  }, [mlsID, rerendering])

  let listDate
  if(houseData.list_date)
  {
    let listDay = (houseData.list_date)
    let listDiv = listDay.search("T")
    listDate = listDay.substring(0,listDiv)
  }

  if(houseData)
  {
    housePrice = houseData.price
    
    if(houseData.building_size)
    {
      houseSize = houseData.building_size.size
      houseUnits = houseData.building_size.units
    }
    if(houseData.address)
    {
      houseCity = houseData.address.city
      houseState = houseData.address.state
      houseLine = houseData.address.line
      houseZip = houseData.address.postal_code
      houseLat = houseData.address.lat
      houseLon = houseData.address.lon
      houseYear = houseData.year_built
      //setLatLongPoint([parseFloat(houseLat), parseFloat(houseLon)])

      
    }
  }


  const SubmitPrice = async (evt) => 
  {
    if(evt)
    { 
      evt.preventDefault(); 
    }
    

    let houseVars = {...dailyHouseVars}
    let mlsChosen = houseVars.mlsID 
    let stateChosen = houseVars.stateID



    //let pointsEarned = Math.floor( 100*Math.pow(0.999969,(Math.abs(priceSub-housePrice)) ) )
    let percent = (Math.abs(priceSub-housePrice) / housePrice)
    let pointsEarned = Math.round( 500*(1-2*(Math.pow(percent,2)) ))

    if (pointsEarned < 0)
    {
      setOgGuess(0)
    }
    else
    {
      setOgGuess(pointsEarned)
    }

    if(readTax == true)
    {
      pointsEarned = (pointsEarned - (500*(0.1)))
    }

    if(readTrend == true)
    {
      pointsEarned = (pointsEarned - (500*(0.2)))
    }

    setPercentageScore(percent)
    if (pointsEarned < 0)
    {
      pointsEarned = 0
    }

    let showScore  = pointsEarned + 1

    
    setPriceDiff(showScore)  

    
    // let entry =
    // {
    //  "state":`${stateChosen}`, 
    //  "mls": `${mlsChosen}`,
    //  "points": `${pointsEarned}`       
    // }
    let todaysDate = new Date()
    let todaysEpoch = String(todaysDate.getTime())
    
    let dailyData = 
    {
      'time':`${todaysEpoch}`,
      'key':`${mlsChosen}_${stateChosen}`,
      'points':`${pointsEarned}`,
      'percent':`${percent}`
    }
    localStorage.setItem('daily', JSON.stringify(dailyData) )

    let keyName = `daily:${todaysEpoch}.${mlsChosen}.${stateChosen}`
    let entry = { [keyName]:`${pointsEarned}` }
    if(currentUser && currentUser.uid)
    {
      let userID = `${currentUser.uid}`
      let previousData = {}

      let userStorage = JSON.parse(localStorage.getItem('userData'))

      if (userStorage == null)
      {
        //console.log("RUNNING Users FIREBASE CALL")
        await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
        .then((doc) => {
          if (doc.exists) 
          {
              //console.log("Document data:", doc.data());
              //doc.data()
              previousData = doc.data()
              localStorage.setItem('userData', JSON.stringify(doc.data()) )
          } 
          else 
          {
              // doc.data() will be undefined in this case
              console.log("No such user!");
          }
        }).catch((error) => {
            console.log("Error getting user:", error);
        });
      }
      else 
      {
        previousData = userStorage
        //console.log("didnt run Submit Price Users firebase read because data is in local storage", userStorage)
      }

      if (previousData)
      {
        //console.log("User data:", previousData);
        let userData = previousData
        let prevKey = `${mlsChosen}_${stateChosen}`

        if (userData[prevKey])
        {
          //console.log("has already played this map")
          keyName = `daily:${todaysEpoch}.${mlsChosen}.${stateChosen}`
        }
        else 
        {
          keyName = `${mlsChosen}_${stateChosen}`
        }
      }


      

      let addr = `${houseLine}, ${houseCity}, ${houseState}, ${houseZip}`

      let thumbNail = houseData.photos[0].href
      let trim = thumbNail.substring(0, (thumbNail.length-5))
      let betterThumbNail = trim.concat("od-w1024_h768_x2.webp")

      previousData[keyName] = [pointsEarned,betterThumbNail]

      let accuracy = ((1-percent)*100).toFixed(2)
      if (accuracy < 0)
      { accuracy = 0} 
      //console.log("accuracy is ", accuracy)

      if(houseData.price > 1000000 && accuracy > 90.0)
      {
        let badgeKey = "badge:Millionaire.Club"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        { 
          completed = 1
        }
        let goal = 10
        let des = "Score over 90% on houses greater than $1,000,000"
        let icon = "MillionairClub.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }

      if(houseSize > 3000 && accuracy > 80.0)
      {
        let badgeKey = "badge:Mansion.Master"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        { 
          completed = 1
        }
        let goal = 20
        let des = "Score over 80% on houses larger than 3000 sqft"
        let icon = "Mansion.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }
      if(houseData.baths > houseData.beds)
      {
        let badgeKey = "badge:Oval.Office"
        let pointsVar = 0
        let completed 
        if(previousData[badgeKey])
        { 
          completed = parseInt(previousData[badgeKey][2]) 
        }
        if(completed)
        { 
          completed += 1 
        }
        else
        {  
          completed = 1
        }
        let goal = 50
        let des = "Complete 50 houses that have more bathrooms than bedrooms"
        let icon = "OvalOffice.svg"
        previousData[badgeKey] = [pointsVar,des,completed,goal,icon]

      }

      //console.log("entry would be", previousData,"user is", userID)

      //console.log("SETTING USERS FIREBASE CALL")
      firebase.firestore().collection('Users').doc(String(userID)).set(previousData)
      
      localStorage.setItem('userData', JSON.stringify(previousData) )
      //console.log("updated userData in localstorage", localStorage)

    }


    let dateObj = new Date();
    let monthDaily = dateObj.getMonth() + 1; //months from 1-12
    let dayDaily = dateObj.getDate();
    let yearDaily = dateObj.getFullYear();

    let newDate = monthDaily + "_" + dayDaily + "_" + yearDaily;

    console.log("todays date",newDate)
    //firebase.firestore().collection('DailyRank').doc(String(newDate)).set(previousData)

    let dailyRankData
    await firebase.firestore().collection('DailyRank').doc(String(newDate)).get()
    .then((doc) => 
    {
      if (doc.exists) 
      {
          //console.log("Document data:", doc.data());
          //doc.data()
          dailyRankData = doc.data()
      } 
      else 
      {
          // doc.data() will be undefined in this case
          console.log("No such daily rank!");
      }
    }).catch((error) => {
        console.log("Error getting daily rank:", error);
    });

    let playerCount = 0,totalSum = 0,devSum = 0
    if(dailyRankData)
    {
      playerCount = parseInt(dailyRankData.play_count)
      totalSum = parseInt(dailyRankData.total_sum)
      devSum = parseInt(dailyRankData.deviation_sqrd_sum)

      console.log("3 vars are",playerCount,totalSum,devSum)
    }

    let avgScore = 0
    if(totalSum > 0)
    {
      avgScore = totalSum/playerCount
    }
    
    //setAverageScore(avgScore)
    console.log("average score was",avgScore)

    totalSum += (showScore - 1)
    playerCount += 1
    devSum += Math.pow(((showScore - 1)-avgScore),2)

    avgScore = totalSum/playerCount

    let stdDev = Math.sqrt(devSum/playerCount)
    console.log("Mean =",avgScore,"| Std dev =",stdDev)

    setMeanScore(avgScore)
    setStdDevSigma(stdDev)

    let newDailyRank = { "play_count": playerCount, "total_sum":totalSum,"deviation_sqrd_sum":devSum}
    firebase.firestore().collection('DailyRank').doc(String(newDate)).set(newDailyRank)
    


    let results = []
      
    let dailyAccuracy = (1-percent)
    if (dailyAccuracy < 0)
    { dailyAccuracy = 0} 
    let dailyPoints = pointsEarned
    let maxBox = 8
    let sc = Math.floor(dailyAccuracy*maxBox)

    //let dayEpoch = new Date()
    //let dayID = (String(dayEpoch.getMonth()+1)).padStart(2, '0').concat((String(dayEpoch.getDate())).padStart(2, '0'))
    //let dayIDYear = String(String(dayEpoch.getFullYear())).concat(dayID)

    let dayID = dailyList.indexOf(`${mlsChosen}_${stateChosen}`) + 1
    results.push(`#Appraisir #Appraisir${dayID}\n`)
    results.push(`📈${Math.floor(dailyAccuracy*100)}% ✨${dailyPoints}\n`)

    //results.push("🏘️🌳🏘️🌳🏘️🌳🏘️🌳 \n")
    //results.push(`💲${dailyDiff}🔺 \n`)
    for (let i = 0; i < maxBox; i++) 
    {
      if(i < sc)
      { results.push('🟩') }
      else
      { results.push('🟥') }
    }
    results.push('\n')
    results.push("https://Appraisir.com/Daily \n")
    let tempShareOutput = String(results.join(""))

    setShareOutput(tempShareOutput)

    console.log(tempShareOutput)

    setPriceComma("")
    
    let num = Math.random() + Math.random() + Math.random()
    setRerender(num)
    //console.log("rerender was just called")

    setTimeout(() => 
    {  
      setModalShow(true)
      if(!currentUser)
      {
        setHideInput(true)
        setTrend({median:'filler'})
      }
    }, 600);

  }
  function GetZPercent(z) 
  {
    //z == number of standard deviations from the mean

    //if z is greater than 6.5 standard deviations from the mean
    //the number of significant digits will be outside of a reasonable 
    //range
    if ( z < -6.5)
      return 0.0;
    if( z > 6.5) 
      return 1.0;

    var factK = 1;
    var sum = 0;
    var term = 1;
    var k = 0;
    var loopStop = Math.exp(-23);
    while(Math.abs(term) > loopStop) 
    {
      term = .3989422804 * Math.pow(-1,k) * Math.pow(z,k) / (2 * k + 1) / Math.pow(2,k) * Math.pow(z,k+1) / factK;
      sum += term;
      k++;
      factK *= k;

    }
    sum += 0.5;

    return sum;
  }

  const LoadWithState = async (evt) => 
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    if(searchParams)
    {
      stateChosen = String(searchParams.get('state'))
    }

    let houseVars = {...dailyHouseVars}
    stateChosen = houseVars.stateID

    LoadStateHouse(stateChosen,userData)

    setTimeout(() => 
    {  
      let num = Math.random() + Math.random() + Math.random()
      setHideInput(false)
      setRerender(num)
      setReadTax(false)
      setReadTrend(false)
      setTrend({})
      //console.log("Called rerender")
    }, 600);
  
  }
  const LoadWithRandom = async (evt) => 
  {
    if(evt)
    { evt.preventDefault(); }
    LoadRandomHouse(userData)

    setTimeout(() => 
    {  
      let num = Math.random() + Math.random() + Math.random()
      setHideInput(false)
      setRerender(num)
      setReadTax(false)
      setReadTrend(false)
      setTrend({})
      //console.log("Called rerender")
    }, 600);
  }

  function Comma(Num) 
  { //function to add commas to textboxes
    if (Num == null)
    {
      Num = ''
    }
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    let x = Num.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }

  const sharePopover = (
    
    <Popover id="popover-basic">
      <Popover.Body>
        Copied to Clipboard!
      </Popover.Body>
    </Popover>
  );


  function NewScoreModal(props) 
  {
    let searchParams
    if (typeof window !== 'undefined') 
    { searchParams = new URLSearchParams(window.location.search); }

    let stateChosen
    let mlsChosen
    if(searchParams)
    {
      stateChosen = searchParams.get('state')
      mlsChosen = searchParams.get('mls')
    }

    let accuracy = ((1-percentageScore)*100).toFixed(2)
    if (accuracy < 0)
    { accuracy = 0} 
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            <div style={{display:"inline"}}>
              You scored <span style={{color:"#00DD00",fontWeight:"800"}}>{priceDiff - 1}</span> points (Max score 500). <SocialButtons/>
            </div>
            {/*<OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
              <Button onClick={() => {navigator.clipboard.writeText(shareOutput)}} style = {{color: "white",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} title="Share Results"  className="alignText">
                Share!
              </Button > 
            </OverlayTrigger>
            */}
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>

          <p>

            
            {( () =>
              {
                
                let sec, taxRead, trendRead, original 

                let labelText = ( () => { return (<>Actual list price is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span></>) } )()

                let pBar = ( () => { return (
                <>
                  <Table striped bordered hover variant={`${dark ? 'dark' : ''}`} style={{ width:'100%'}}>
                    <thead>
                      <tr>
                        <th>Your Guess</th>
                        <th>List Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(priceSub).replace(/\D/g,''))}</span>
                        </td>
                        <td>
                          <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  
                  You were <span style={{color:"#00DD00",fontWeight:"800"}}>{accuracy}%</span> accurate.
                  <ProgressBar style={{backgroundColor:'#00dd00 !important', width:'100%',fontSize:'15px',height:'20px'}} now={accuracy} /> 
                  
                </> 
                ) } )()

                if ((ogGuess) <= 0)
                { 
                  //console.log("IN HERE")
                  sec = ( () => { return (<><br/> You need to be within <span style={{color:"#00DD00",fontWeight:"700"}}>30%</span> accuracy to score points. <br/></>) } )()
                  
                }
                else
                {
                  if (readTax)
                  {
                    original = ( () => { return (<><br/>Base score: <span style={{fontWeight:"900"}}>{ogGuess}</span></>) } )()
                    taxRead = ( () => { return (<><br/><span style={{color:"#DD2222",fontWeight:"900"}}>-50</span> for using Tax Assessed Value.</>) } )()
                  }
                  if (readTrend)
                  {
                    original = ( () => { return (<><br/>Base score: <span style={{fontWeight:"900"}}>{ogGuess}</span></>) } )()
                    trendRead = ( () => { return (<><br/><span style={{color:"#DD2222",fontWeight:"900"}}>-100</span> for using Average Home Price.</>) } )()
                  }
                }
                let askLogIn
                if (!currentUser)
                {
                  askLogIn = ( () =>
                  {
                    return(
                      <>
                        &#9702;&nbsp;<Link to="/Signup" style={{ color:"#00DD00",fontSize:"1.1rem",fontWeight:"800"}}>Make an account</Link> to track your score!
                      </>
                    )
                  })()
                }
                let learnMore = ( () => { return (
                <><br/><Link to="/About" style={{ color:"#00DD00",fontSize:"1.1rem",fontWeight:"800"}}>Learn more</Link> about how the score is calculated </>) } )()

                let fillerData = {'mu':meanScore,'sigma':stdDevSigma,'score':(priceDiff-1)}
                let zScore
                if (stdDevSigma > 0)
                { 
                  zScore = (((priceDiff-1) - meanScore) / stdDevSigma) 
                  console.log("zscore ---- ",zScore)
                }
                else
                {
                  zScore = -4
                }
                let percentile = Math.round( GetZPercent(zScore) * 100 )
                return (
                <> 
                  {pBar} {sec} {original}{taxRead} {trendRead} <br/> 
                  <div style={{textAlign:'left'}}>
                    <h3>Global Rank:</h3>
                    <BellCurve data = {fillerData} />
                    <h4>You scored higher than <span style={{color:"#00DD00",fontWeight:"700"}}>{percentile}%</span> of all players</h4>
                    {learnMore} {askLogIn}  
                  </div>    
                </>)

              }
            )()}
          </p>
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <div className="scoreBottomButton-grid">
              
            <div className="scoreBottomButtonBoxes">
              <Button style={{fontWeight:"800",height: 'inherit', textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
              onClick = { () => 
              {
                LoadWithRandom() 
                props.onHide()
              } }>
                New Random Home
              </Button>
            </div>
            <div className="scoreBottomButtonBoxes">
              <Button style={{fontWeight:"800",height: 'inherit', textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}
              onClick = { () => 
              {
                LoadWithState() 
                props.onHide()
              } }>
                New {houseState} Home
              </Button>
            </div>
            <div className="scoreBottomButtonBoxes">
              <Button style={{height: 'inherit'}} variant="danger" onClick={props.onHide}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function TaxModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Tax Assessed Value
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <p>
            The tax assessed value takes into account the property condition, square footage, and sales of similar homes.
            The actual sale price can be much greater or lower than the tax assesed value.
          </p>
          <p className="thicc">
            Unlocking the tax assessed value will lower your score by 50.
          </p>
          <br/>
          <Button onClick={()=>{
            setReadTax(true) 
            setTaxModalShow(false)
          }} style={{ fontSize:"1.5rem",fontWeight:"500",color:"white"}} variant="primary">
            Show Taxes?
          </Button>
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function TrendModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Average House Price
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          { (() => {
            //console.log("trend",trend)


            function toTitleCase(str) 
            {
              return str.replace(
                /\w\S*/g,
                function(txt) 
                {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
              );
            }

            function modString(str)
            {
              if( str == 'home')
              {
                str = 'Average Property'
              }
              str = str.replace(/_/g, ' ');
              return toTitleCase(str)
            }

            if( readTrend )
            {
              return(
                <>
                  <Table striped bordered hover variant={`${dark ? 'dark' : ''}`} style={{ width:'100%', margin:'auto'}}>
                    <tbody>
                      
                        {
                          Object.entries(trend).map((item,ind) =>
                          {
                            //console.log("prop",item[1])
                            if(item[1] && item[1].listing_price)
                            {
                              //console.log("int here")
                              return (
                              <>  
                              <tr>
                                <td style={{color:"#00DD00",fontWeight:"800"}}>
                                  ${Comma(String(item[1].listing_price).replace(/\D/g,''))}
                                </td>
                                <td key={`trend-${ind}`} >
                                  {modString(item[0])}
                                </td>
                              </tr>
                              </>
                              )
                            }
                          })
                        }
                    </tbody>
                  </Table>
                  
                </>
              )
            }
            else
            {
              return(
                <>
                  <p className="thicc">
                    This will show the average house price in the area by house type.  <br/>             
                  </p>
                  <p className="thicc">
                    Unlocking this will lower your Final Score by 100.
                  </p>
                  <br/>
                  <Button onClick={ ()=>
                  {
                    setReadTrend(true) 
                    //setTrendModalShow(false)
                  }} 
                  style={{ fontSize:"1.5rem",fontWeight:"500",color:"white"}} variant="primary">

                    Show Average Price

                  </Button>
                </>
              )
            }
          })()
          }
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const BigMap = useCallback(({ center, zoom }) => {
    const [map, setmap] = useState(null);
    if (map) {
      map.flyTo(center);
    }
    //console.log("center is",center,"zoom is",zoom)
    return (
      <>
        <MapContainer style={{width:'100%',height:'350px'}}
          center={center}
          zoom={zoom}
          attributionControl={false}
          zoomControl={false}
          whenCreated={setmap}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></TileLayer>
          <Circle center={center} pathOptions={{color: '#00DD00'}} radius={400}></Circle>
          {/*
            <Marker position={center}>
            </Marker>
          */}
        </MapContainer>
      </>
    );
  }, [houseData])

  const SmallMap = useCallback(({ center, zoom }) => {
    const [map, setmap] = useState(null);

    useEffect(() => {
      if (map) {
        map.flyTo(center);
      }
    }, [])
    //console.log("center is",center,"zoom is",zoom)
    return (
      <>
        <MapContainer style={{width:'100%',height:'150px'}}
          center={center}
          zoom={zoom}
          attributionControl={false}
          zoomControl={false}
          whenCreated={setmap}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></TileLayer>
          <Circle center={center} pathOptions={{color: '#00DD00'}} radius={400}></Circle>
          {/*
            <Marker position={center}>
            </Marker>
          */}
        </MapContainer>
      </>
    );
  }, [houseData])

  function MapModal(props) 
  {
    return (
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Full Map
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant = {`${dark ? 'white' : ''}`} />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          
          <BigMap center={[parseFloat(houseData.address.lat),parseFloat(houseData.address.lon)]} zoom={13}/>
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: `${dark ? '#222' : 'white'}`,color: `${dark ? 'white' : 'black'}`}}>
          <Button variant="danger" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function SocialButtons ()
  {
    return(
      <>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
        <div class="share-btn-container">
          Share:&nbsp;
          <a  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareOutput)}&via=appraisir`} title="Tweet Score" class="twitter-btn" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
          
          <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareOutput)}`} title="Send on WhatsApp" class="whatsapp-btn" target="_blank">
            <i class="fab fa-whatsapp"></i>
          </a>

          <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
            <a  onClick={() => {navigator.clipboard.writeText(shareOutput)}} title="Copy to Clipboard"  className="alignText">
              <i class="fa-solid fa-clipboard-list"></i>
            </a > 
          </OverlayTrigger>

        {/*
          <a href={`https://www.facebook.com/sharer.php?u=https://appraisir.com/Daily`} class="facebook-btn" target="_blank">
            <i class="fab fa-facebook"></i>
          </a>

          <a href={`https://pinterest.com/pin/create/bookmarklet/?media=${houseData.photos[0].href}&url=https://appraisir.com/Daily&description=${encodeURIComponent(shareOutput)}`} class="pinterest-btn" target="_blank">
            <i class="fab fa-pinterest"></i>
          </a>

          <a href={`https://www.linkedin.com/shareArticle?url=https://appraisir.com/Daily&title=${encodeURIComponent(shareOutput)}`} class="linkedin-btn" target="_blank">
            <i class="fab fa-linkedin"></i>
          </a>
        */
        }
        </div>
      
      </>
    )
  }
  
  return (
    <PageLayout>
       <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
              integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==" crossorigin=""/>
      <SEO title="Daily Home" />

      {(()=>
        {
          if (listItems == null)
          {
            return( 
            <>
              <h3>The Daily Home is the same for everyone (next one loads at 12AM).</h3>
              <br/>
              <table style={{margin:'auto'}}>
                <tr>
                  <td>
                    <Button style={{fontWeight:"900", width:"100%", textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
                    onClick={LoadWithRandom}>
                      New Random Home
                    </Button> 
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <Button style={{fontWeight:"900",width:"100%",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}
                    onClick={LoadWithState}>
                      New {houseState} Home
                    </Button> 
                  </td>
                </tr>
              </table>
              <br/>
            </>)
          }
        }
        )()
      }
      
      <div>

          
        {(()=>
          {
            if (alreadyPlayed == false && trend.median != "filler") // if user has not played house
            {
              if (trend.median == "none") // if no trend data
              {
                return( 
                <h3> 
                  Guess the price! 
                </h3>  )
              }
              else
              {
                return( 
                  <h3> 
                    Guess the price! &nbsp; 
                    <Button onClick={()=>{setTrendModalShow(true)}} style={{fontWeight:"700",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} variant="primary"> 
                      Hint? 
                    </Button> 
                  </h3>  )
              }
            }
            else if (alreadyPlayed == false && trend.median == "filler") //if user is not logged in and just submitted a price
            {
              return( 
              <>
                <h3>You Scored {priceDiff - 1}! <SocialButtons/>
                  {/*
                  <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
                    <Button onClick={() => {navigator.clipboard.writeText(shareOutput)}} style = {{color: "white",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} title="Share Results"  className="alignText">
                      Share!
                    </Button > 
                  </OverlayTrigger>
                  */}
                </h3>
              </>)
            }
            else //if user is logged in and has a previously submitted price (aka alreadyPlayed = true)
            {
              return( 
              <>
                <h3>You Scored {previousScore}! <SocialButtons/>
                {/*
                  <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
                    <Button onClick={() => {navigator.clipboard.writeText(shareOutput)}} style = {{color: "white",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} title="Share Results"  className="alignText">
                      Share!
                    </Button > 
                  </OverlayTrigger>
                */}
                  
                </h3>
              </>)
            }
          }
          )()
        }
        
      </div>

      
        
      {(()=>
          {
            const parceInp = useCallback((e) =>
            {
              let val = e.target.value
              val = String(val).replace(/\D/g,'');
              let ret = Comma(val)
              setPriceComma(ret)
              let valClean = String(val).replace(',','')
              setPriceSub(valClean) 
            },[setPriceComma])

            if (alreadyPlayed == false && hideInput == false)
            {
              return(
              <div className="inputPrice">
                <InputGroup >
                  <InputGroup.Text style={{backgroundColor:"#00BB00",borderColor:"#00BB00",color:"white", fontSize:"1.5rem",fontWeight:"900"}}>$</InputGroup.Text>
                  <FormControl size="lg" variant="success" type="text" value={priceComma} onChange={(e) => {parceInp(e)}} 
                    onKeyPress = { (e) =>
                    {
                      //console.log(e.key)
                      if(e.key === 'Enter')
                      {
                        SubmitPrice()
                      }
                    }} placeholder="Input a price" />
                  <Button onClick={SubmitPrice} style={{ fontSize:"1.5rem",fontWeight:"500", backgroundColor:"#00BB00",borderColor:"#00BB00"}} variant="success" value="Submit">Submit</Button>
                </InputGroup>
              </div>
              )
            }
            else if (alreadyPlayed == false && hideInput == true)
            {
              return( 
              <>
                <h3> The house price is ${Comma(String(housePrice).replace(/\D/g,''))}</h3> 
                <h4><Link to="/Signup" style={{ color:"#00DD00",fontWeight:"800"}}>Make an account</Link> to track your score!</h4>
              </>)
            }
            else 
            {
              return( <h3> The house price is <span style={{color:"#00DD00",fontWeight:"800"}}>${Comma(String(housePrice).replace(/\D/g,''))}</span> </h3> )
            }
          }
          )()
        }
        
      <br/>

      
      <div className="pageMargin pt-6 mt-6 text-color">
        <div className="basic-grid">
          
          <div className="boxes pics-grid ">
            
            {priceDiff ?  <NewScoreModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          /> : ` `}

            
            {taxModalShow ?  <TaxModal
                            show={taxModalShow}
                            onHide={() => setTaxModalShow(false)}
                          /> : ` `}
            
            {trendModalShow ?  <TrendModal
                            show={trendModalShow}
                            onHide={() => setTrendModalShow(false)}
                          /> : ` `}

            {mapModalShow ?  <MapModal
                            show={mapModalShow}
                            onHide={() => setMapModalShow(false)}
                          /> : ` `}

            <div className="picSize">
              <Carousel images={pics} transitionDurationMax={40} style={{ height: 400, backgroundColor:'transparent' }} hasMediaButton={false} hasIndexBoard={false} />
            </div>
            
          </div>
          <div className="boxes text-left thicc " >
            <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
              
            <div className="addrMap-grid">
              <div className="addrMapBoxes">
                {(() => 
                  {
                    if (alreadyPlayed == false && hideInput == false)
                    {
                      return(
                        <>
                          {houseCity}, {houseState}, {houseZip} <br/>
                        </>
                      )
                    }
                    else
                    {
                      return( 
                        <>
                          {houseLine}, {houseCity}, {houseState}, {houseZip} <br/>
                        </>
                        )
                    }
                  }
                  )()
                }
                    
                {houseData.beds} <div className="thin">Beds</div> {houseData.baths} <div className="thin">Baths</div>&nbsp; 
                {houseSize} <div className="thin">{houseUnits}</div> {houseData.style} <br/>

                {(() => 
                  {
                    if(houseYear)
                    {
                      return(
                        <> {houseYear} <div className="thin">Year Built</div> <br/> </>
                      )
                    }
                  })()
                } 
                {(() => 
                  {
                    if(listDate)
                    {
                      return(
                        <> { listDate } <div className="thin">List Date</div> <br/> </>
                      )
                    }
                  })()
                } 

              </div>
              <div className="addrMapBoxes">
                {(() => 
                  {
                    if(houseData && houseData.address)
                    {
                      return(
                        <div style={{position:'relative'}}>
                          <SmallMap center={[parseFloat(houseData.address.lat),parseFloat(houseData.address.lon)]} zoom={12}/>
                          <button onClick={()=>{setMapModalShow(true)}} style={{opacity:'0.75',backgroundColor:'transparent',border:'none', position:'absolute',bottom:'5px',zIndex:'900'}}> 
                            
                            <svg style={{stroke:'#555',fill:'#fff'}} height="30" preserveAspectRatio="xMidYMid meet" role="img" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
                              <title>Full Map</title>
                              <path d="m1 1v10h5v-5h5v-5zm18 0v5h5v5h5v-10zm-18 18v10h10v-5h-5v-5zm18 5v5h10v-10h-5v5z"></path>
                            </svg>
                          </button>
                        </div>
                      )
                    }
                  })()
                }  
              </div>
            </div> 
            <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
          </div>

          
          <div className="boxes text-left thicc scrolling">
            <u>Description:</u><br/>
            <div className="thin">{houseData.description}</div>
            
          </div>
          <div className="boxes text-left features-grid thicc ">
            <Accordion>
              <Accordion.Item eventKey="0" style={{borderColor:'#bbb',borderWidth:'1px'}}>
                <Accordion.Button style={{backgroundColor:`${dark ? '#222' : 'white'}`,color:`${dark ? 'white' : 'black'}`,fontWeight:'900',fontSize:'1.2rem' }}><u>Details:</u></Accordion.Button>
                <Accordion.Body >
                  <div className="thin feat-grid">
                    {(()=>
                      { 
                        //console.log("tax assesed ",taxAss.length)
                        if (taxAss.length > 0)
                        {
                          if(!readTax)
                          {
                            return(
                              <>
                                <div className="featBoxes">
                                  <div className="mid">Tax Assessed Value </div>
                                  <Button onClick={()=>{setTaxModalShow(true)}} style={{fontWeight:"700", width:"80%", margin:"0 auto", fontSize:"1.2rem", textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} 
                                  variant="primary">Unlock Tax Hint?</Button>
                                </div>
                              </>
                            )
                          }
                          else
                          {
                            return(
                            <>
                              <div className="featBoxes">
                                <div className="mid">Tax Assessed Value </div>
                                <ul>
                                  { taxAss && taxAss.map((e,ind) => 
                                    {
                                      if(e.assessment)
                                      {
                                        return (
                                          <div key={`tax-${ind}`} >
                                            <li>{e.year} - ${e.assessment.total}</li>
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                </ul>
                              </div>
                            </>
                            )
                          }
                        }
                        else
                        {
                          return(
                            <>
                              <div className="featBoxes">
                                <div className="mid">Tax Assessed Value </div>
                                <div style={{fontWeight:"600",color:"red"}}>There is no Tax data available for this house. </div>
                              </div>
                            </>
                          )
                        }
                      }
                      )()
                    }
                    { 
                      feats.map((e,ind) => 
                      {
                        return (
                          <div key={`featCat-${ind}`} className="featBoxes" >
                            <div className="mid">{e.category} </div>
                            <ul>
                              {
                                e.text.map((f,index) =>
                                {
                                  return (
                                    <li key={`featList-${index}`}>
                                      {f}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        )
                      })
                    }
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>    
          </div>  
        </div>

        
        
      </div>
      
    </PageLayout>
  )

}